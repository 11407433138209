<template>
    <div id="content" class="notifications">
        <div class="page-container">
            <div class="news-container" v-if="Object.values(allNews).length > 0">
                <div class="column-container">
                    <div class="news-list-title">
                        {{ $t('news.list_title') }}
                    </div>
                    <div class="news-list">
                        <div class="item-release" v-for="release in allNews" :key="release.id">
                            <div class="item" @click="selectNew(release)" :class="{ selected: newSelected == release.id }">{{ release.title }}</div>
                        </div>
                    </div>
                </div>
                <transition name="slide-fade" mode="out-in">
                    <div v-if="newSelected" :key="newSelected" class="new-container">
                        <div class="title-new">{{ oNew.title }}</div>
                        <div class="date">{{ formatTime(oNew.created_date * 1000) }}</div>
                        <div class="content scrollbar" @change="changeHrefs()" v-html="oNew.text"></div>
                    </div>
                </transition>
            </div>
            <div v-else class="centered">
                <EmptyTable :title="$t('empty_table.users_title')" :description="$t('news.no_releaeses')"></EmptyTable>
            </div>
        </div>
    </div>
</template>

<script>
import EmptyTable from '../components/ui/EmptyTable.vue'
export default {
    components: { EmptyTable },
    name: 'News',
    data() {
        return {
            newSelected: '',
            linkList: false
        }
    },
    computed: {
        user() {
            return this.$store.getters['login/getLocalEmployee']
        },
        visibleTools() {
            var visible = []
            if (localStorage.config) {
                var config = JSON.parse(localStorage.config)
                if (config.tools) {
                    visible = config.tools
                }
            }
            return visible
        },

        news() {
            var news = this.$store.getters['login/getReleases']()
            return news
        },
        allNews() {
            var allNew = this.$store.getters['login/getAllReleases']()
            if (this.$route.params.selected && Object.values(this.news).length > 0) {
                for (var i in allNew) {
                    var idx = allNew[i]
                    if (idx.id == this.$route.params.selected) {
                        this.selectNew(idx)
                    }
                }
            } else {
                if (Object.values(allNew).length > 0) {
                    this.newSelected = Object.values(allNew)[0].id
                }
            }
            return allNew
        },
        oNew() {
            var selectedNew = this.$store.getters['login/getAllReleases'](this.newSelected)
            return selectedNew
        }
    },
    methods: {
        formatTime(value) {
            return moment(value).format('DD MMMM YYYY')
        },
        load() {
            var self = this
            this.$overlay.show()
            this.$store.dispatch('login/loadReleases').then(function () {
                self.$overlay.hide()
            })
            this.$overlay.hide()
        },
        selectNew(release) {
            this.newSelected = release.id
            var releasesViewed = JSON.parse(localStorage.getItem('release_viewed'))
            if (releasesViewed != null) {
                for (var index in releasesViewed) {
                    if (!releasesViewed.filter((e) => e === release.id).length > 0) {
                        var AnewsViewed = []
                        for (var i in releasesViewed) {
                            AnewsViewed.push(releasesViewed[i])
                        }
                        AnewsViewed.push(release.id)
                        localStorage.removeItem('release_viewed')
                        localStorage.setItem('release_viewed', JSON.stringify(AnewsViewed))
                    }
                    // console.log(releasesViewed.find(element => element.id == idx.id))
                }
            } else {
                var AnewsViewed = []
                AnewsViewed.push(release.id)
                localStorage.removeItem('release_viewed')
                localStorage.setItem('release_viewed', JSON.stringify(AnewsViewed))
            }
        },
        linkAction(a) {
            var nameref = a.href
            if (a.className == 'panel') {
                nameref = nameref.replace(window.location.origin, '')
                if (a.target == '_blank') {
                    window.open(nameref, 'blank')
                } else {
                    this.$router.push(nameref)
                }
            } else {
                if (a.target == '_blank') {
                    window.open(a.href, '_blank')
                } else {
                    location.href = a.href
                }
            }
        }
    },
    created() {
        this.load()
    },
    watch: {
        newSelected: function () {
            var self = this

            setTimeout(function () {
                self.linkList = document.getElementsByClassName('link-buttons')
                for (var i = 0; i < self.linkList.length; i++) {
                    self.linkList[i].onclick = function (event) {
                        event.preventDefault()
                        self.linkAction(event.target)
                    }
                }
            }, 300)
        }
    }
}
</script>

<style lang="scss">
#content.notifications {
    padding: 1em;

    .page-container {
        width: 100%;
        // height: calc(100% - (63px + 23px))
        height: 100%;
        // @include box-shadow($h: 0px, $v: 0px, $b: 12px, $c: rgba($color-neutral-600, 0.1));
        @include border-radius(3px);
    }

    .centered {
        @include display-flex();
        @include justify-content();
        @include align-self(center);
        width: 100%;
        height: 100%;
    }
    .news-container {
        @include display-flex();
        // @include box-shadow($h: 0px, $v: 0px, $b: 12px, $c: rgba($color-neutral-600, 0.1));
        // @include border-radius(3px);
        overflow: hidden;
        // width: 80%;
        padding-top: 3em;
        width: 100%;

        @media (min-width: 1200px) {
            width: 1100px;
            gap: 1em;
        }

        @media (min-width: 1400px) {
            width: 1200px;
        }

        @media (min-width: 1600px) {
            width: 1400px;
        }

        background-color: $color-neutral-50;
        // height: calc(100% - (63px + 23px));
        height: 100%;
        display: flex;
        margin: 0 auto;
        // margin-top: 63px;
        // margin-top: 3em;

        .slide-fade-enter-active {
            transition: all 0.2s ease;
        }
        .slide-fade-leave-active {
            transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
        }
        .slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
            transform: translateX(30px);
            opacity: 0;
        }

        .column-container {
            // width: 300px;
            width: 35%;
            height: 100%;
            padding: 30px 30px;

            .news-list-title {
                @include font-size(ml);
                font-family: $text-bold;
                color: $color-black;
                padding-bottom: 20px;
            }

            .news-list {
                overflow-y: scroll;
                height: auto;
                max-height: 100%;
                padding-bottom: 15px;
                width: 100%;
                .item-release {
                    width: 100%;

                    .item {
                        @include text-ellipsis();
                        @include font-size(sm);
                        line-height: 40px;
                        height: 40px;
                        font-family: $text-medium;
                        color: $color-black;
                        cursor: pointer;
                        padding-left: 12px;
                        padding-right: 12px;
                        width: 100%;

                        &.selected {
                            @include border-radius(4px);
                            @include font-size(sm);
                            box-shadow: 0px 0px 6px rgba(119, 119, 119, 0.1);
                            background-color: #fff !important;
                            color: $color-black;
                            font-family: $text-bold;
                        }

                        &:hover {
                            background-color: hover(#fff);
                        }
                    }
                }
            }
        }
        .new-container {
            // width: calc(100% - 300px);
            // padding-left: 10px;
            width: 100%;
            padding: 30px 2em;

            .title-new {
                @include font-size(lg);
                font-family: $text-bold;
                color: $color-black;
                padding-bottom: 25px;
            }
            .date {
                @include font-size(xs);
                color: $color-neutral-600;
                font-family: $text-medium;
                margin-top: -25px;
                padding-bottom: 10px;
            }
            .content {
                overflow-y: scroll;
                height: auto;
                max-height: 100%;
                padding-bottom: 50px;

                img {
                    width: 100%;
                    height: auto;
                    max-width: 400px;
                }

                div {
                    @include font-size(md);
                    font-family: $text-medium;
                    color: $color-black;
                    padding-top: 6px;
                    padding-bottom: 6px;
                }
                p {
                    @include font-size(md);
                    font-family: $text-medium;
                    color: $color-black;
                    padding: 6px 0px;
                }
                ul {
                    padding-top: 6px;
                    padding-bottom: 6px;
                    li {
                        @include font-size(md);
                        font-family: $text-medium;
                        color: $color-black;
                        list-style: disc;
                    }
                }
                ul li::marker {
                    font-size: 0.8rem;
                }
                ol {
                    padding-top: 6px;
                    padding-bottom: 6px;
                    li {
                        @include font-size(md);
                        font-family: $text-medium;
                        color: $color-black;
                        list-style: decimal;
                    }
                }
                h1 {
                    @include font-size(lg);
                    font-family: $text-bold;
                    color: $color-black;
                }
                h2 {
                    @include font-size(ml);
                    font-family: $text-bold;
                    color: $color-black;
                }
                a {
                    font-size: inherit;
                    color: $color-primary-500;
                    text-decoration: underline;
                    text-decoration-color: $color-primary-500;
                }
                a.link-buttons {
                    @include align-items(center);
                    @include background($image: img('right_arrow_secondary.svg'), $position: right 6px center, $size: 12px);
                    @include border-radius(4px);
                    @include font-size(xs);
                    background-color: #fff;
                    padding-right: 25px;
                    padding-left: 12px;
                    box-shadow: 0px 0px 6px rgba(119, 119, 119, 0.1);
                    height: 40px !important;
                    width: fit-content;
                    display: inline-flex;
                    margin-right: 6px;
                    color: $color-primary-500;
                    font-family: $text-bold;
                    text-decoration: none;

                    &:hover {
                        background-color: hover(#fff);
                    }
                }
                .footer {
                    @include font-size(xs);
                    color: $color-neutral-600;
                    font-family: $text-medium;
                }
            }
        }
    }
}
</style>
